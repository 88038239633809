import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useCallback, useState } from 'react';
import { useVehicleInfo } from '../../context/vehicleInfo';
import { formatSeperatedInteger } from '../../utils/format';
import { getMileageValidationError } from '../../utils/validation';
import { IOfferRefreshDialogProps } from '../OfferRefreshDialog';

const OfferRefreshDialog = ({ closeDialog, setOtherChanges }: IOfferRefreshDialogProps) => {
    const { vehicleConditionInfo, setVehicleConditionInfo } = useVehicleInfo();
    const [stepCounter, setStepCounter] = useState(0);
    const [wasTouched, setWasTouched] = useState(false);
    const [mileageError, setMileageError] = useState<string>(null);

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const formatted = formatSeperatedInteger(event.target.value);
        setMileageError(getMileageValidationError(formatted));

        setVehicleConditionInfo(prev => ({
            ...prev,
            mileage: formatted,
        }));
    }, []);

    const steps = [
        {
            body: (
                <>
                    <TextField
                        error={wasTouched && mileageError ? true : false}
                        helperText={mileageError ? mileageError : ''}
                        label="Enter mileage"
                        onBlur={() => setWasTouched(true)}
                        onChange={handleChange}
                        size="medium"
                        slotProps={{
                            htmlInput: { inputMode: 'numeric', maxLength: 7 },
                            inputLabel: { required: false },
                        }}
                        value={vehicleConditionInfo.mileage}
                        variant="outlined"
                    />
                    <Button onClick={() => setStepCounter(1)} disabled={mileageError ? true : false}>
                        Update details
                    </Button>
                </>
            ),
            header: 'Update your Mileage',
        },
        {
            body: (
                <>
                    <Button onClick={() => setOtherChanges(false)}>No more changes</Button>
                    <Button onClick={() => setOtherChanges(true)} variant="outlined">
                        Edit car details
                    </Button>
                </>
            ),
            header: 'Any other changes?',
        },
    ];

    return (
        <Dialog
            maxWidth={'md'}
            onClose={closeDialog}
            open={true}
            PaperProps={{ sx: { borderRadius: 2, minWidth: '300px', padding: 3 } }}
        >
            <Stack gap={2}>
                <Stack alignItems="center" direction="row" justifyContent="space-between">
                    <Typography variant="h6">{steps[stepCounter].header}</Typography>
                    <IconButton color="secondary" onClick={closeDialog}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
                <Stack gap={2}>{steps[stepCounter].body}</Stack>
            </Stack>
        </Dialog>
    );
};

export default OfferRefreshDialog;
