// Adobe Analytics tracking functions
import React from 'react';

export const ADOBE_ECID_COOKIE = 'kndctr_0C1038B35278345B0A490D4C_AdobeOrg_identity';
export const ADOBE_AMCV_COOKIE = 'AMCV_0C1038B35278345B0A490D4C@AdobeOrg';

export const getAdobeCookie = (key: string): string | undefined => {
    return window._satellite?.cookie.get(key);
};

export const trackAnalyticsEvent = (eventId: string): void => {
    if (window._satellite) {
        window._satellite.track(eventId);
    }
};

export const trackICOInit = (originPage: string, vin: string): void => {
    if (typeof adobeDataLayer != 'undefined') {
        adobeDataLayer.push({
            event: 'icoInit',
            details: {
                origin: originPage,
                vinNumber: vin,
            },
        });
    } else {
        window.digitalData = window.digitalData || {};
        window.digitalData.lead = { originPage };
        window.digitalData.vehicleProfile = { vin };
        clearSourceSection();
        trackAnalyticsEvent('Instant-Cash-Offer_Init');
    }
};

export const trackICOSubmit = (originPage: string, vin: string, quoteId: string): void => {
    if (typeof adobeDataLayer != 'undefined') {
        adobeDataLayer.push({
            event: 'icoSubmit',
            linkDetails: {
                name: 'get my offer',
                position: 'ico-submit',
            },
            details: {
                origin: originPage,
                vinNumber: vin,
                quoteId,
            },
        });
    } else {
        window.digitalData = window.digitalData || {};
        window.digitalData.lead = { originPage, quoteId };
        window.digitalData.vehicleProfile = { vin };

        trackAnalyticsEvent('Instant-Cash-Offer_Submit');
    }
};

export const updateSourceSection = (section: string): void => {
    window.digitalData = window.digitalData || {};

    if (window.digitalData.sourceSection !== 'ICO:Offer Page') {
        window.digitalData.sourceSection = 'ICO:' + section;
    }
};

const clearSourceSection = (): void => {
    if (window.digitalData && window.digitalData.sourceSection) {
        window.digitalData.sourceSection = '';
    }
};

export const trackICOIneligible = (originPage?: string, vin?: string, reason?: string, pageType?: string): void => {
    if (typeof adobeDataLayer != 'undefined') {
        adobeDataLayer.push({
            event: 'icoIneligible',
            details: {
                origin: originPage,
                vinNumber: vin,
            },
            ineligible: {
                pageType,
                reason: `ICO: Ineligible Screen|ICO Ineligible Reason|${reason}`,
            },
        });
    } else {
        window.digitalData = window.digitalData || {};
        window.digitalData.ineligible = {}; //clear ineligible data
        clearSourceSection();
        window.digitalData.ineligible.pageType = pageType;
        if (reason) {
            window.digitalData.ineligible.reason = `ICO: Ineligible Screen|ICO Ineligible Reason|${reason}`;
        }
        trackAnalyticsEvent('Instant-Cash-Offer_Ineligible');
    }
};

export const triggerGenericEvent = (value: string): void => {
    if (typeof adobeDataLayer != 'undefined') {
        adobeDataLayer.push({
            event: 'icoGeneric',
            details: {
                genericEventData: value,
            },
        });
    } else {
        window.digitalData = window.digitalData || {};
        window.digitalData.genericEventData = value;
        trackAnalyticsEvent('Instant-Cash-Offer_Event');
    }
};

export const getOriginPage = (originPage: string): string => {
    return originPage
        ? originPage
        : (() => {
              const locationPath = window.location.pathname.split('/');
              const path = locationPath[locationPath.length - 1];
              switch (path) {
                  case 'sell-my-car':
                      return 'SUYC_ICO_NewOffer';
                  case '':
                      return 'HP_ICO_NewOffer';
                  case 'checkout':
                      return 'Checkout_ICO_NewOffer';
                  case 'mycarmax':
                      return 'MyCarMax_ICO_NewOffer';
                  case 'appraisals':
                      return 'MyOffers_ICO_NewOffer';
                  default:
                      return 'UnknownOrigin_ICO_NewOffer';
              }
          })();
};

/* eslint react/display-name: 0 */
export const withAnalyticsClickTracking = (Component: any) => {
    return ({ ...props }) => {
        const onClick = (e: any) => {
            if (typeof adobeDataLayer != 'undefined') {
                adobeDataLayer.push({
                    event: 'ctaClick',
                    linkDetails: {
                        name: e.target.textContent.toLowerCase() || null,
                        position: props.id || null,
                    },
                });
            }
            props.onClick?.(e);
        };

        return <Component {...props} onClick={onClick} />;
    };
};
