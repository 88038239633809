import axios, { AxiosResponse } from 'axios';

const getIMG2StockPhotoData = async (vehicleImage: VehicleImage): Promise<string | null> => {
    const requestUrl = getIMG2StockPhotoUrl(vehicleImage);
    return axios({
        method: 'get',
        url: requestUrl,
        responseType: 'blob',
    })
        .then((result: AxiosResponse<Blob>) => {
            // Check to see that the requested URL matches the final (possibly redirected) response URL. This will catch a redirect to the "coming soon" image.
            if (requestUrl == result.request?.responseURL) {
                return window.URL.createObjectURL(result.data);
            } else {
                // "Coming Soon" redirect
                return null;
            }
        })
        .catch(() => {
            return null as string;
        });
};

const getIMG2StockPhotoUrl = (vehicleImage: VehicleImage): string => {
    return (
        'https://img2.carmax.com/assets/mmy-' +
        encodeURIComponent(vehicleImage.make) +
        '-' +
        encodeURIComponent(vehicleImage.model) +
        '-' +
        encodeURIComponent(vehicleImage.year) +
        '/image/1.jpg?width=300'
    );
};

const getContentServiceStockPhotoData = async (vehicleImage: VehicleImage): Promise<string | null> => {
    const stockPhotoUrl = getContentServiceStockUrl(vehicleImage);
    return await axios({
        method: 'get',
        url: stockPhotoUrl,
        responseType: 'blob',
    })
        .then((response: AxiosResponse<Blob>) => {
            if (stockPhotoUrl === response.request?.responseURL) {
                return window.URL.createObjectURL(response.data);
            }

            return null;
        })
        .catch(() => {
            return null as string;
        });
};

const getContentServiceStockUrl = (vehicleImage: VehicleImage): string => {
    return `https://content-images.carmax.com/stockimages/${encodeURIComponent(
        vehicleImage.year
    )}/${encodeURIComponent(vehicleImage.make)}/${encodeURIComponent(vehicleImage.model)}/st2400-089-evoxwebsmall.png?width=300`;
};

export interface VehicleImage {
    make: string;
    model: string;
    year: number;
}

const cleanupParameter = (vehicleImage: VehicleImage): VehicleImage => {
    const cleanVehicleImage: VehicleImage = {
        ...vehicleImage,
    };

    // Replace whitespace with a dash
    cleanVehicleImage.make = vehicleImage.make.replace(/\s+/g, '-').toLowerCase();

    // Replace ampersand with "and"
    cleanVehicleImage.model = vehicleImage.model.replace(/&/g, 'and');

    // Replace whitespace with a dash
    cleanVehicleImage.model = cleanVehicleImage.model.replace(/\s+/g, '-').toLowerCase();

    return cleanVehicleImage;
};

const getVehicleImageData = async (vehicleImage: VehicleImage): Promise<string | null> => {
    const cleanVehicleImage = cleanupParameter(vehicleImage);
    return getContentServiceStockPhotoData(cleanVehicleImage).then(contentValue => {
        if (contentValue == null) {
            return getIMG2StockPhotoData(cleanVehicleImage);
        }
        return contentValue;
    });
};

export default getVehicleImageData;
