import { IDecodeResp } from '../api/decoder';
import { IKbbValuation } from '../api/kbb';
import { IOfferEventVehicleInformation } from '../types/IOfferEventVehicleInformation';
import { IContinuation, IOffer } from '../types/IOfferResponse';
import { IActiveStoreAppraisal } from '../types/IStartQuote';
import { IVehicleInfo } from '../types/IVehicleInfo';
import { trackICOIneligible } from './analytics';
import { eligibleType, ineligibleType, saveResultToLocalStorage, storeAdditionalCarOffer } from './localStorage';

interface IOfferRequestPreparedEvent {
    vehicleInformation: IOfferEventVehicleInformation;
}

interface IOfferAvailableEvent {
    offerAmount: number;
    offerCode: string;
    expirationDateUtc: Date;
    expirationDisplayDate: string;
    appraisalId: string;
    storeId: string;
    zipCode: string;
    vehicleInformation: IOfferEventVehicleInformation;
}

export interface IVehicleIneligibleEvent {
    isSecondIneligible: boolean;
    ccsRequestId?: string;
    ccsRequestUrl?: string;
    reason?: string;
    quoteId?: string;
    zipCode?: string;
    vehicleInformation: IOfferEventVehicleInformation;
    isPicsyEligible: boolean;
    picsyUrl?: string;
}

export interface IVehicleIneligibleEventParams {
    saveResult: boolean;
    isSecondIneligible: boolean;
    vehicleInfo: IVehicleInfo;
    mileage: number;
    continuation: IContinuation;
    quoteId?: string;
    reason?: string;
    pageType?: string;
    isPicsyEligible?: boolean;
    picsyUrl?: string;
}

export const defaultIneligibleParams = {
    isSecondIneligible: false,
    mileage: null,
    continuation: null,
    reason: null,
    pageType: null,
    quoteId: null,
} as IVehicleIneligibleEventParams;

interface IVehicleDecodedEvent {
    vehicle: IDecodeResp;
}

export const EVENTS = {
    vehicleDecodeStarted: 'vehicleDecodeStarted',
    vehicleIneligible: 'vehicleIneligible',
    vehicleDecoded: 'vehicleDecoded',
    plateDecodeError: 'plateDecodeError',
    zipCodeDecodeError: 'zipCodeDecodeError',
    offerRequestPrepared: 'offerRequestPrepared',
    offerAvailable: 'offerAvailable',
    icoStarted: 'icoStarted',
    initialFormSubmitted: 'initialFormSubmitted',
    killSwitch: 'killSwitch',
};

const getVehicleInfo = (vehicleInfo: IVehicleInfo, mileage: number): IOfferEventVehicleInformation => ({
    vin: vehicleInfo.vin,
    mileage: mileage,
    profile: {
        year: parseInt(vehicleInfo.profile.year),
        make: vehicleInfo.profile.make,
        model: vehicleInfo.profile.model,
        trim: vehicleInfo.profile.trim,
        makeCode: vehicleInfo.profile.makeCode,
        modelCode: vehicleInfo.profile.modelCode,
        trimCode: vehicleInfo.profile.trimCode,
    },
});

export const offerRequestPrepared = (vehicleInfo: IVehicleInfo, mileage: number): void => {
    window.dispatchEvent(
        new CustomEvent(EVENTS.offerRequestPrepared, {
            detail: {
                vehicleInformation: getVehicleInfo(vehicleInfo, mileage),
            } as IOfferRequestPreparedEvent,
        })
    );
};

export const activeStoreAppraisal = (
    saveResult: boolean,
    vehicleInfo: IVehicleInfo,
    offer: IActiveStoreAppraisal
): void => {
    const expirationDateUtc = new Date(offer.expiredTimestampDisplay);
    if (saveResult) {
        saveResultToLocalStorage(eligibleType, {
            isStoreAppraisal: true,
            offerAmount: offer.amount,
            offerCode: offer.code,
            expirationDateUtc: new Date(expirationDateUtc.setDate(expirationDateUtc.getDate() + 1)),
            expirationDisplayDate: offer.expiredTimestampDisplay,
            storeId: offer.storeId,
            zipCode: vehicleInfo.zipcode,
            vehicleInformation: getVehicleInfo(vehicleInfo, offer.mileage),
            kbbValues: null,
            redemptionCertificateUri: null,
        });
        storeAdditionalCarOffer({
            createdDateUtc: new Date(expirationDateUtc.setDate(expirationDateUtc.getDate() - 7)),
            expirationDateUtc: new Date(expirationDateUtc.setDate(expirationDateUtc.getDate() + 1)),
            expirationDisplayDate: offer.expiredTimestampDisplay,
            isPicsyEligible: false,
            isStoreAppraisal: true,
            offerAmount: offer.amount,
            offerCode: offer.code,
            offerType: 'eligible',
            picsyUrl: '',
            storeId: offer.storeId,
            vehicleInformation: getVehicleInfo(vehicleInfo, offer.mileage),
            vin: vehicleInfo.vin,
            zipCode: vehicleInfo.zipcode,
        });
    }
    window.dispatchEvent(
        new CustomEvent(EVENTS.offerAvailable, {
            detail: {
                isStoreAppraisal: true,
                offerAmount: offer.amount,
                offerCode: offer.code,
                expirationDateUtc: new Date(expirationDateUtc.setDate(expirationDateUtc.getDate() + 1)),
                expirationDisplayDate: offer.expiredTimestampDisplay,
                appraisalId: offer.code,
                storeId: offer.storeId,
                zipCode: vehicleInfo.zipcode,
                vehicleInformation: getVehicleInfo(vehicleInfo, offer.mileage),
                kbbValuation: null,
            } as IOfferAvailableEvent,
        })
    );
};

export const offerAvailable = (
    saveResult: boolean,
    vehicleInfo: IVehicleInfo,
    mileage: number,
    offer: IOffer,
    kbb: IKbbValuation
): void => {
    if (saveResult) {
        saveResultToLocalStorage(eligibleType, {
            offerAmount: offer.valuation,
            offerCode: offer.code,
            expirationDateUtc: new Date(offer.expiresDateUtc),
            expirationDisplayDate: offer.expiresDisplayDate,
            storeId: offer.storeId,
            zipCode: offer.zipCode,
            vehicleInformation: getVehicleInfo(vehicleInfo, mileage),
            kbbValues: kbb,
            redemptionCertificateUri: offer.redemptionCertificateUri,
        });
        storeAdditionalCarOffer({
            createdDateUtc: new Date(offer.createdDateUtc),
            expirationDateUtc: new Date(offer.expiresDateUtc),
            expirationDisplayDate: offer.expiresDisplayDate,
            isPicsyEligible: false,
            isStoreAppraisal: false,
            offerAmount: offer.valuation,
            offerCode: offer.code,
            offerType: 'eligible',
            picsyUrl: '',
            storeId: offer.storeId,
            vehicleInformation: getVehicleInfo(vehicleInfo, mileage),
            vin: vehicleInfo.vin,
            zipCode: vehicleInfo.zipcode,
        });
    }
    window.dispatchEvent(
        new CustomEvent(EVENTS.offerAvailable, {
            detail: {
                offerAmount: offer.valuation,
                offerCode: offer.code,
                expirationDateUtc: new Date(offer.expiresDateUtc),
                expirationDisplayDate: offer.expiresDisplayDate,
                appraisalId: offer.code,
                storeId: offer.storeId,
                zipCode: offer.zipCode,
                vehicleInformation: getVehicleInfo(vehicleInfo, mileage),
                kbbValuation:
                    kbb == null
                        ? null
                        : {
                              PriceAdvisorUrl: kbb.priceAdvisorUrl,
                              Valuation: kbb.valuation,
                              ValuationRangeLow: kbb.valuationRangeLow,
                              ValuationRangeHigh: kbb.valuationRangeHigh,
                          },
            } as IOfferAvailableEvent,
        })
    );
};

export const vehicleIneligible = (params: IVehicleIneligibleEventParams): void => {
    const {
        mileage,
        pageType,
        saveResult,
        quoteId,
        vehicleInfo,
        isSecondIneligible,
        reason,
        isPicsyEligible = false,
        picsyUrl,
    } = params;

    if (saveResult && vehicleInfo) {
        saveResultToLocalStorage(ineligibleType, {
            expirationDateUtc: new Date(new Date().setDate(new Date().getDate() + 7)),
            isPicsyEligible,
            picsyUrl,
            make: vehicleInfo.profile.make,
            model: vehicleInfo.profile.model,
            offerCode: quoteId,
            vin: vehicleInfo.vin,
            year: vehicleInfo.profile.year,
        });
    }

    trackICOIneligible(null, vehicleInfo?.vin, reason, pageType);
    window.dispatchEvent(
        new CustomEvent(EVENTS.vehicleIneligible, {
            detail: {
                isSecondIneligible,
                reason,
                quoteId,
                zipCode: vehicleInfo?.zipcode,
                vehicleInformation: vehicleInfo ? getVehicleInfo(vehicleInfo, mileage) : null,
                isPicsyEligible,
                picsyUrl,
            } as IVehicleIneligibleEvent,
        })
    );
};

export const vehicleDecoded = (decodeResp: IDecodeResp): void => {
    window.dispatchEvent(
        new CustomEvent(EVENTS.vehicleDecoded, {
            detail: {
                vehicle: decodeResp,
            } as IVehicleDecodedEvent,
        })
    );
};

export const initialFormSubmitted = (vin: string, licensePlate: string, state: string, zipCode: string): void => {
    window.dispatchEvent(
        new CustomEvent(EVENTS.initialFormSubmitted, {
            detail: {
                vin,
                licensePlate,
                state,
                zipCode,
            },
        })
    );
};

export const icoStart = (): void => {
    window.dispatchEvent(new CustomEvent(EVENTS.icoStarted));
};

export const vehicleDecodeStarted = (): void => {
    window.dispatchEvent(new CustomEvent(EVENTS.vehicleDecodeStarted));
};

export const plateDecodeError = (plate: string, state: string, reason: string): void => {
    window.dispatchEvent(
        new CustomEvent(EVENTS.plateDecodeError, {
            detail: {
                reason,
                plate,
                state,
            },
        })
    );
};

export const zipCodeDecodeError = (zipCode: string, reason: string): void => {
    window.dispatchEvent(
        new CustomEvent(EVENTS.zipCodeDecodeError, {
            detail: {
                reason,
                zipCode,
            },
        })
    );
};

export const killSwitch = (): void => {
    window.dispatchEvent(new CustomEvent(EVENTS.killSwitch));
};
