import Cookies from 'js-cookie';
import { IEligibleOffer, IIneligibleOffer, IMultipleOfferStorage, StoredOffer } from '../types/SavedResult';

export const eligibleInstantOfferLocalStorageName = 'suycIcoOffer';
export const ineligibleInstantOfferLocalStorageName = 'suycIcoOfferIneligible';
export const eligibleType = 'eligible';
export const ineligibleType = 'ineligible';

export const multipleInstantOfferLocalStorageName = 'suycMultipleIcoOffer';

export const saveResultToLocalStorage = (type: string, result: IEligibleOffer | IIneligibleOffer): void => {
    resetSavedResult();
    window.localStorage.setItem(
        type === eligibleType ? eligibleInstantOfferLocalStorageName : ineligibleInstantOfferLocalStorageName,
        JSON.stringify(result)
    );
    // Cookies.set(type === eligibleType ? eligibleInstantOfferLocalStorageName : ineligibleInstantOfferLocalStorageName, JSON.stringify(result));
};

export const getResultFromLocalStorage = (type: string): IEligibleOffer | IIneligibleOffer => {
    try {
        // let offerJson = Cookies.get(type === eligibleType ? eligibleInstantOfferLocalStorageName : ineligibleInstantOfferLocalStorageName);
        let offerJson = null;
        if (!offerJson) {
            offerJson = window.localStorage.getItem(
                type === eligibleType ? eligibleInstantOfferLocalStorageName : ineligibleInstantOfferLocalStorageName
            );
        }
        return offerJson ? JSON.parse(offerJson) : null;
    } catch (ex) {
        console.error('Error retrieving saved offer', ex);
    }
    return null;
};

export const resetSavedResult = (): void => {
    try {
        window.localStorage.removeItem(eligibleInstantOfferLocalStorageName);
        window.localStorage.removeItem(ineligibleInstantOfferLocalStorageName);
        Cookies.remove(eligibleInstantOfferLocalStorageName);
        Cookies.remove(ineligibleInstantOfferLocalStorageName);
    } catch (err) {
        console.error('error resetting ICO state', err);
    }
};

//Disable for non-KMX hosts in prod
export const checkHost = (): boolean => {
    if (process.env.ENV === 'production' && window.location.host === 'www.carmax.com') {
        return true;
    }
    if (process.env.ENV !== 'production') {
        return true;
    }
    return false;
};

export const storeAdditionalCarOffer = (offer: StoredOffer): void => {
    const offerVin: string = offer.vin;

    const garageString = window.localStorage.getItem(multipleInstantOfferLocalStorageName);

    let garage: IMultipleOfferStorage;
    if (garageString != null) {
        garage = JSON.parse(garageString) as IMultipleOfferStorage;
    } else {
        // initialize the object
        garage = {
            offers: {},
            lastOfferVin: '',
        };
    }

    garage.offers[offerVin] = offer;
    garage.lastOfferVin = offerVin;

    window.localStorage.setItem(multipleInstantOfferLocalStorageName, JSON.stringify(garage));
};
